import { ApplicationController } from "./application_controller.js"
import tippy from 'tippy.js'

export default class extends ApplicationController {
  static targets = [ "source" ]
  static values = {
    source: String
  }
  copy(event) {
    console.log("click!", event)
    if (this.valueToCopy){
      navigator.clipboard.writeText(this.valueToCopy)
      tippy(this.clickableElement, {duration: [0,500], content: "Copied!", delay: [0,1000]}).show()
    }
  }

  get valueToCopy(){
    if (this.hasSourceValue){
      return this.sourceValue
    }
    if (this.hasSourceTarget){
      return this.sourceTarget.value
    }
  }

  get clickableElement(){
    if (this.hasSourceTarget){
      return this.sourceTarget
    } else {
      return this.element
    }
  }
}
