import { ApplicationController } from "./application_controller.js"
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import AwsS3Multipart from '@uppy/aws-s3-multipart'

export default class extends ApplicationController   {

  connect() {
    console.log("hi trix upload controller")
    console.log('tus endpoint is', this.tusEndpoint);
    var controller = this
    this.form = this.element.closest('form')
    this.uppyMap = {}
    this.attachmentMap = {}
    this.uppy = Uppy({
      autoProceed: true,
      allowMultipleUploads: true,
      debug: true,
      meta: {
        user: this.userSgid,
        organization: this.organizationSgid
      },
    })
    .on('upload', (data) => {
      // on upload start
      controller.form.querySelectorAll('[type=submit]').forEach((button) => {
        button.disabled = true
      })

    })
    .on('complete', (data) => {
      // on upload end
      controller.form.querySelectorAll('[type=submit]').forEach((button) => {
        button.disabled = false
      })
    })
    .on('error', (error) => {
      if (window.Rollbar){
        window.Rollbar.error("Uppy upload error", error);
      }
    })
    .on('upload-error', (file, error, response) => {
      if (window.Rollbar){
        window.Rollbar.error("Uppy upload-error", error);
      }
    })
    .on('upload-success', (file, response) => {
      console.log('upload-success fired')
      console.log("file", file)
      console.log('response:', response)
      var filedata = {
        upload_url: response.uploadURL,
        name: file.name,
        type: file.type,
        size: file.size,
        metadata: file.meta,
        visibility: 'public',
      }
      fetch('/blob/upload.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-Csrf-Token': this.csrfToken,
          'Accept': 'application/json',
        },
        body: JSON.stringify(filedata),
        credentials: 'same-origin',
      })
      .then((response) => {
        console.log('parsed!')
        return response.json()
      }).then((json) => {
        var attachmentId = this.uppyMap[file.id]
        if (attachmentId) {
          // need to
          console.log("found attachment id ",attachmentId)
          console.log('json: ', json)
          var attachment = controller.attachmentManager.managedAttachments[attachmentId].attachment
          // call blobs endpoint with TUS info, then call setAttributes to
          // provide the proper upload URL
          attachment.setAttributes({
            url: json.urls.thumbnail,
            href: json.urls.browser,
            previewable: true,
          })
        }
      })
    })
    .on('upload-progress', (file, progress) => {
      var attachmentId = this.uppyMap[file.id]
      if (attachmentId) {
        var p = (progress.bytesUploaded / progress.bytesTotal) * 100
        if (controller.attachmentManager.managedAttachments[attachmentId]){
          controller.attachmentManager.managedAttachments[attachmentId].attachment.setUploadProgress(p)
        }
        console.log('upload-progress updating progress as', p)
      } else {
        console.log('upload-progress no attachment found for file ', file.id)
      }
    })
    // .use(Tus, {endpoint: this.tusEndpoint})
    .use(AwsS3Multipart, {companionUrl: '/'})

    this.element.addEventListener('trix-file-accept',function(e){
      console.log('intercepted trix-file-accept', e);
    })
    this.element.addEventListener("trix-attachment-add", function(e){
      console.log('intercepted trix-attachment-add', e);
      controller.uploadAttachment(e.attachment)
      e.preventDefault();
    })
  }

  uploadAttachment(attachment){
    this.attachmentManager = attachment.attachmentManager

    var file = attachment.file;
    var href = attachment.getAttribute("href");
    var url = attachment.getAttribute("url");

    console.log(attachment);

    if (!file.name && file.type) {
      var mimeParts = file.type.split("/");
      if (mimeParts[0] == "image") {
        fileExtension = "." + mimeParts[1];
        file.name = "image" + fileExtension;
        attachment.setAttributes({ filename: "image" + fileExtension })
      } else {
        file.name = "pasted";
        attachment.setAttributes({ filename: "pasted" })
      }
    }

    if (!href && url){
      if (!pastedUrlToAttachment(attachment, url)) {
        return;
      }
    }

    var fileId = this.uppy.addFile({
      name: file.name, // file name
      type: file.type, // file type
      data: file, // file blob
    })
    this.uppyMap[fileId] = attachment.attachment.id
    this.attachmentMap[attachment.attachment.id] = fileId
    console.log(fileId)
  }

  dataURItoBlob(dataURI) {
      // https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
      // convert base64/URLEncoded data component to raw binary data held in a string
      console.log('dataURItoBlob');
      console.log(dataURI);
      var byteString, fileExtension;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
          byteString = atob(dataURI.split(',')[1]);
      else
          byteString = unescape(dataURI.split(',')[1]);

      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {type:mimeString});
  }

  pastedUrlToAttachment(attachment, url){
      console.log("pasted data has URL, download and attach");
      // take the full URL, wipe everything prior to the last slash
      // also remove any parameters
      var baseFilename = url.replace(/^.*[\\\/]/,'').replace(/\?.*$/,'');
      // if it looks like a valid filename with extension
      if (baseFilename.match(/\.[a-z]{3,4}/i)) {
        attachment.setAttributes({ filename: baseFilename });
      } else {
        attachment.setAttributes({ filename: "paste" });
      }
      var value = async () => {
        return await fetch(url)
          .then((response) => {
            console.log("download success: converting downloaded image data to b64");
            var contentType = response.headers.get("Content-type");
            var isImage = (contentType.indexOf("image/") == 0) ||
              (contentType == "binary/octet-stream");
            if (isImage){
              var data = async() => {await response.blob()}
              try {
                var arr = new Uint8Array(data);
                // Convert the int array to a binary string
                // We have to use apply() as we are converting an *array*
                // and String.fromCharCode() takes one or more single values, not
                // an array.
                // https://stackoverflow.com/questions/20035615/using-raw-image-data-from-ajax-request-for-data-uri
                var raw = String.fromCharCode.apply(null,arr);
                var b64=btoa(raw);
                var dataURL="data:"+contentType+";base64,"+b64;
                attachment.file = dataURItoBlob(dataURL);
                return true;
              } catch (e) {
                // if we can't convert it, just link it
                console.log(['fail:',e]);
                attachment.setAttributes({ href: url })
                return true;
              }
            } else {
              console.log('fail, not an image',e);
              attachment.remove();
              alert("Rescue Hub was not able to handle this attachment.")
              return null;
            }
          })
          .catch((error) => {
            attachment.remove();
            alert("Rescue Hub was not able to handle this attachment.")
            return null;
          })

      }
      return value;
  }
}
