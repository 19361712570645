import { ApplicationController } from "./application_controller.js"
export default class extends ApplicationController   {
  static targets = [ "iframe","fullScreenButton" ]
  connect() {
    const statusUrl = this.element.dataset.statusUrl
    const formId = this.element.id
    // this.check()
    setTimeout(function(){
      fetch(statusUrl, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
        credentials: 'same-origin',
      })
        .then(function(response) {
          return response.text()
        }).then(function(text) {
          document.getElementById(formId).innerHTML = text
          console.log('text', text)
        }).catch(function(ex) {
          console.log('parsing failed', ex)
        })
    }, 5000);
  }

}
