import { ApplicationController } from "./application_controller.js"
export default class extends ApplicationController   {
  static targets = [ "name","heading","display","input" ]
  connect() {
    console.log(this.element)
  }

  edit() {
    this.inputTarget.classList.remove("hidden")
    this.headingTargets.forEach((headingTarget, i) => {
      headingTarget.classList.add("hidden")
    })
    console.log("edit action fires")
  }
  save() {
    this.inputTarget.classList.add("hidden")
    this.headingTargets.forEach((headingTarget, i) => {
      headingTarget.classList.remove("hidden")
    })
    console.log("save action fires")
  }
  keyup(){
    this.displayTargets.forEach((displayTarget, i) => {
      displayTarget.innerHTML = this.nameTarget.value
    })
  }

}
