import { ApplicationController } from "./application_controller.js";
export default class extends ApplicationController {
  static targets = ["field"];

  fieldTargetConnected(target) {
    this.addClass(target, 'number-field-hide-spinner')
  }

  plus(event) {
    event.preventDefault()
    if (!this.hasFieldTarget) {
      return;
    }
    const { increment } = event.params;
    const amount = increment ? Number(increment) : 0.5;
    const oldValue = Number(this.fieldTarget.value || "0.0");
    this.fieldTarget.value = oldValue + amount;
  }

  minus(event) {
    event.preventDefault()
    if (!this.hasFieldTarget) {
      return;
    }
    const { decrement } = event.params;
    const amount = decrement ? Number(decrement) : 0.5;
    const oldValue = Number(this.fieldTarget.value || "0.0");
    if (oldValue - amount > 0.0){
      this.fieldTarget.value = oldValue - amount;
    }
  }
}
