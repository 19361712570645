import { ApplicationController } from "./application_controller.js"
export default class extends ApplicationController {
  static targets = [ "button","radio","duration" ]
  connect() {
    // console.log(this.element)
  }

  toggle(e){
    console.log(['event',e])
    var clicked = e.target
    console.log(['clicked',clicked])
    if (!(clicked.localName == "input" && clicked.type == "radio")){
      console.log("skipping, click target isn't the checkbox");
      return true;
    } else {
      console.log("click target is the checkbox");      
    }
    var radio = clicked;
    var button = radio.closest("label");
    console.log(['radio',radio])
    console.log(['durations',this.durationTargets])
    if (button.classList.contains("active")){
      console.log('btn is active, deactivate')
      button.classList.remove("active")
      e.preventDefault()
      radio.checked = false
      this.durationTargets.forEach((durationTarget, i) => {
        console.log([durationTarget,'set to null'])
        durationTarget.value = null
      })
    } else {
      console.log('btn is inactive, activate')
      this.buttonTargets.forEach((buttonTarget, i) => {
        buttonTarget.classList.remove("active")
      })
      if (radio.dataset.absentPresent == "absent"){
        this.durationTargets.forEach((durationTarget, i) => {
          console.log([durationTarget,'set to null'])
          durationTarget.value = null
        })
      }
      if (radio.dataset.absentPresent == "present"){
        this.durationTargets.forEach((durationTarget, i) => {
          console.log([durationTarget,'set to',durationTarget.dataset.default])

          durationTarget.value = durationTarget.dataset.default
        })
      }
      button.classList.add("active")
    }
    // console.log("am I the element clicked?")
    // console.log(this.element)
  }
}
