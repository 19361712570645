import { ApplicationController } from "./application_controller.js"
// used when accepts_nested_attributes_for is used in a form.
// set data-controller="nested-attributes-row" on the form tag,
// add a button with data-action="nested-attributes-row#add" to add a row
// add a button with data-action="nested-attributes-row#remove" to remove a row
// set data-nested-attributes-row-target="row" on each row to be added/removed
// see views/task_sheet/template_assignments/new.html.erb for an example
export default class extends ApplicationController   {
  static targets = [ "row" ]
  connect() {
    console.log("na-row")
  }

  add(event){
    var randomID = Math.round(Math.random() * 1000000000)
    event.preventDefault()
    console.log(event)
    console.log(this.rowTargets)
    var lastRow = this.rowTargets[this.rowTargets.length - 1]
    var rowContainer = lastRow.parentNode
    var newRow = lastRow.cloneNode(true)
    console.log(newRow)
    newRow.querySelectorAll("select").forEach(function(item, i){
      item.selectedIndex = 0
      item.name = item.name.replace(/\]\[[0-9]+\]\[/,']['+randomID+'][')
    });
    newRow.querySelectorAll("input").forEach(function(item, i){
      item.name = item.name.replace(/\]\[[0-9]+\]\[/,']['+randomID+'][')
      if (item.name.match(/\[id\]$/)) {
        item.value = ""
      }
    });
    this.showElement(newRow);
    console.log("new ID is "+ randomID)
    console.log(newRow)
    rowContainer.appendChild(newRow)
  }

  remove(event){
    event.preventDefault()
    var button = event.target
    console.log("click!")
    if (this.rowTargets.length > 0) {
      this.rowTargets.forEach((row, i) => {
        if (row !== button && row.contains(button)) {
          let tags = row.getElementsByTagName("input")
          console.log(tags)
          for (var i = 0; i < tags.length; i++) {
            let item = tags[i]
              if (item.name.match(/\[_destroy\]$/) && item.type == "checkbox") {
                item.checked = true
                console.log("checking checkbox",item)
              }
          }
          console.log("found row!")
          this.hideElement(row)
        }
      })
    }
  }
}
