import { ApplicationController } from "./application_controller.js"
export default class extends ApplicationController   {
  static targets = [ "concealed","triggerContainer","count" ]
  connect() {
    var controller = this
    console.log("show-more")
    if (this.hasCountTarget){
      console.log(this.countTarget, this.concealedTargets.length)
      this.countTarget.innerHTML = this.concealedTargets.length
    }
    if (this.hasConcealedTarget){
      this.concealedTargets.forEach((concealed, idx) => {
        controller.addClass(concealed, 'hidden')
      })
    }
  }

  reveal(e){
    e.preventDefault()
    var controller = this
    var clicked = e.target
    console.log({event: e})
    var scoped = clicked.dataset.scope
    this.concealedTargets.forEach((concealed, idx) => {
      if (scoped){
        if (concealed.dataset.scope == scoped){
          controller.removeClass(concealed, 'hidden')
        }
      } else {
        controller.removeClass(concealed, 'hidden')
      }
    })
    this.triggerContainerTargets.forEach((trigger, idx) => {
      if (scoped){
        if (trigger.dataset.scope == scoped){
          trigger.parentNode.removeChild(trigger)
          controller.addClass(trigger, 'hidden')
        }
      } else {
        trigger.parentNode.removeChild(trigger)
      }
    })

  }

}
