import { ApplicationController } from "./application_controller.js"
const { guessBrowser } = require('@twilio/webrtc/lib/util');
export default class extends ApplicationController   {
  connect(){
    if (this.isSupported){
      console.log('twilio video supported')
      this.element.classList.add('twilio-video-supported');
    } else {
      console.log('twilio video not supported')
      this.element.classList.add('twilio-video-not-supported');
    }
  }

  isRTCPeerConnectionSupported() {
    return typeof RTCPeerConnection !== 'undefined'
      || typeof webkitRTCPeerConnection !== 'undefined'
      || typeof mozRTCPeerConnection !== 'undefined';
  }

  /**
   * Check whether GetUserMedia API is supported.
   * @returns {boolean}
   */
  isGetUserMediaSupported() {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
      || !!(navigator.getUserMedia)
      || !!(navigator.webkitGetUserMedia)
      || !!(navigator.mozGetUserMedia);
  }

  /**
   * Check whether the current browser is non-Chromium Edge.
   * @param {string} browser
   * @returns {boolean}
   */
  isNonChromiumEdge(browser) {
    return browser === 'chrome' && /Edge/.test(navigator.userAgent) && (
      typeof chrome === 'undefined' || typeof chrome.runtime === 'undefined'
    );
  }

  /**
   * Check if the current environment is supported by the SDK.
   * @returns {boolean}
   */
  get isSupported() {
    const browser = guessBrowser();
    return !!browser
      && !this.isNonChromiumEdge(browser)
      && this.isGetUserMediaSupported()
      && this.isRTCPeerConnectionSupported();
  }
}
