import { ApplicationController } from "./application_controller.js";
import TomSelect from "tom-select";
export default class extends ApplicationController {
  static targets = ["category", "deleteContainer", 'deleteButton', "row"];
  connect() {
    console.log("multievent connected", this.element);
    this.categoryTargets.forEach(
      (target) => new TomSelect(target, { allowEmptyOption: false })
    );
  }

  categoryTargetConnected(target) {
    const controller = this;
    this.rowTargets.forEach((element, index) => {
      element.dataset.rowId = index.toString();
    });
    this.deleteButtonTargets.forEach((element, index) => {
      element.dataset.rowId = index.toString();
      console.log(`delete target ${index}`, element);
      if (index == 0) {
        controller.hideElement(element);
      } else {
        controller.showElement(element);
      }
    });
    this.deleteButtonTargets.forEach((element, index) => {
      element.dataset.rowId = index.toString();
    });

  }

  categoryTargetDisconnected(target){
    this.categoryTargetConnected(target)
  }

  addRow(event) {
    var randomID = Math.round(Math.random() * 1000000000);
    event.preventDefault();
    console.log(event);
    console.log(this.rowTargets);
    // deactivate the one to be cloned
    let lastValue;
    const lastTS = this.categoryTargets[this.categoryTargets.length - 1];
    if (lastTS && lastTS.tomselect) {
      lastValue = lastTS.tomselect.getValue();
      console.log("tomselect.destroy()", lastTS);
      lastTS.tomselect.destroy();
    }
    // this.categoryTargets.forEach((target) => {
    //   if (target.tomselect) {
    //     target.tomselect.destroy()
    //   }
    // })
    var lastRow = this.rowTargets[this.rowTargets.length - 1];
    var rowContainer = lastRow.parentNode;
    var newRow = lastRow.cloneNode(true);
    console.log(newRow);
    newRow.querySelectorAll("select").forEach(function (item, i) {
      item.selectedIndex = 0;
      console.log("select name was", item.name)
      item.name = item.name.replace(/multi_event\[[0-9]+\]\[/, "multi_event[" + randomID + "][");
      console.log("select name is now", item.name)
    });
    newRow.querySelectorAll("input").forEach(function (item, i) {
      item.name = item.name.replace(/multi_event\[[0-9]+\]\[/, "multi_event[" + randomID + "][");
      if (item.name.match(/\[id\]$/)) {
        item.value = "";
      }
    });
    this.showElement(newRow);
    console.log("new ID is " + randomID);
    console.log(newRow);
    rowContainer.appendChild(newRow);
    // rebuild source TS
    const source = new TomSelect(lastTS, { allowEmptyOption: false });
    source.setValue(lastValue);
    const destination = this.categoryTargets[this.categoryTargets.length - 1];

    this.categoryTargets.forEach((target) => {
      if (!target.tomselect) {
        console.log("reselecting", target);
        new TomSelect(target, { allowEmptyOption: false });
      }
    });
  }

  removeRow(event) {
    const { target } = event;
    event.preventDefault();
    var rowId = target.dataset.rowId 
    if (!rowId){
      rowId = target.parentNode.dataset.rowId
    }
    console.log(`target.dataset.rowId: ${rowId}`)
    this.rowTargets.forEach((element, index) => {
      console.log(`${rowId} <=> ${element.dataset.rowId}`)
      if (rowId && rowId === element.dataset.rowId){
        element.remove()
      }
    });
  }
}
