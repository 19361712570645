import { ApplicationController } from "./application_controller.js"
import dragula from "dragula"

export default class extends ApplicationController   {
  static targets = ["container","task","handle"]

  connect() {
    console.log('rtt')
    var token = this.csrfToken
    const controller = this 
    var config = {
      moves: function (el, source, handle, sibling) {
        // used to detect whether this is a drag handle or not
        const isHandle = controller.handleTargets.find((target) => target.isEqualNode(handle))
        return !!isHandle
      },
      isContainer: function (el) {
        const isContainer = controller.containerTargets.find((target) => target.isEqualNode(el))
        return !!isContainer
      }
    }
    this.drake = dragula(this.containerTargets,config)
      .on("drop",function(el, target, source, sibling){
        var containers = [target];
        if (target.id != source.id){
          containers.push(source);
        }
        containers.forEach((container, i) => {
          var url = container.dataset.reorderUrl
          var elements = container.querySelectorAll("[data-sortable-id]")
          var sorted = []
          for (var i = 0; i < elements.length; ++i) {
            sorted.push(elements[i].dataset.sortableId)
          }
          var data = {
            reordered_ids: sorted
          }
          fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            credentials: 'same-origin',
            headers:{
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRF-Token': token
            }
          });
        })
      });
  }
}
