import { ApplicationController } from "./application_controller.js"
import Pikaday from 'pikaday'
import moment from 'moment'
import * as chrono from 'chrono-node';
export default class extends ApplicationController {
  static targets = [ 'picker' ]
  connect() {
    this.pikas = []
    this.dateFormat = "MMM D YYYY";
    if (this.hasPickerTarget){
      this.pickerTargets.forEach((e, i) => {
        this.installPika(e);
      });
    } else {
      this.installPika(this.element)
    }
    window.moment = moment
    window.chrono = chrono
  }

  setdate(event){
    event.preventDefault();
    if (this.hasPickerTarget){
      this.pickerTargets.forEach((e, i) => {
        e.value = event.target.dataset.date
      });
    }
  }

  installPika(element){
    console.log('initialize pika on', element)
    element.classList.add('form-control')
    console.log('original value',element.value)
    // if (element.value){
    //   var invalidMatcher = /invalid/i
    //   var parsed = moment(element.value, ['YYYY-MM-DD', this.dateFormat], true).format(this.dateFormat);
    //   if (!invalidMatcher.test(parsed) ){
    //     element.value = parsed
    //   }
    // }
    var picker = new Pikaday({
      field: element,
      format: this.dateFormat,
      toString: (date, format) => {
        console.log("toString input",date,format)
        console.log("toString moment", moment(date))
        return moment(date).format(format);
      },
      parse: (dateString, format) =>{
        console.log("parsed input:", dateString, format)
        if (!dateString){
          return;
        }
        var referenceDate = new Date
        var parsed = chrono.parseDate(dateString, referenceDate)
        if (parsed){
          var parsedDate = new Date(parsed.getFullYear(), parsed.getMonth(), parsed.getDate())
          console.log("parsed date output:", parsed,parsedDate)
          return parsedDate;
        } else {
          console.log('could not parse')
          return null
        }
      }
    });
    this.pikas.push(picker)
  }
}
