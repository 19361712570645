import { ApplicationController } from "./application_controller.js"
export default class extends ApplicationController   {
  connect() {
    if (document.getElementById("sync-options-all").checked) {
      this.all()
    }
    if (document.getElementById("sync-options-some").checked) {
      this.some()
    }

    // console.log("Hello, Stimulus?", this.element)
  }

  some(){
    this.showElement(this.table)
    this.hideElement(this.permissionSelect)
  }

  all(){
    this.hideElement(this.table)
    this.showElement(this.permissionSelect)
  }

  get table(){
    return document.getElementById('task-sheet-template-sync-table')
  }

  get permissionSelect(){
    return document.getElementById('sync-all-permission-select')
  }

}
