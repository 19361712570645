import { ApplicationController } from "./application_controller.js"
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
export default class extends ApplicationController   {
  static targets = [ 'zone', 'attachment','uploads','submit','checkbox',
                     'radio','dateblock','date','duration','metric' ]
  static values = { user: String, upload: String, existingDocuments: String }

  // actions when checkbox is toggled
  toggleSelfCertify(){
    this.toggleSubmit()
    if (!this.hasCheckboxTarget){ return; }
    this.radioTargets.forEach((target)=>{
      target.disabled = !this.checkboxTarget.checked
    })
    this.durationTargets.forEach((target)=>{
      target.disabled = !this.checkboxTarget.checked
    })
    this.metricTargets.forEach((target)=>{
      target.disabled = !this.checkboxTarget.checked
    })
    this.toggleRadio()
  }

  // actions when radio buttons are toggled
  toggleRadio(){
    this.toggleSubmit()
    // master checkbox is not checked: hide the dateblock targets and do nothing further
    if (this.hasCheckboxTarget && !this.checkboxTarget.checked) {
      this.dateblockTargets.forEach(target => this.hideElement(target))
      return
    }

    // radio today checked: hide the dateblock
    // radio other checked: show the dateblock
    this.radioTargets.filter(target => target.checked).forEach((radio)=>{
      if (radio.value == 'today'){
        this.dateblockTargets.forEach(target => this.hideElement(target))
      }
      if (radio.value == 'other'){
        this.dateblockTargets.forEach(target => this.showElement(target))
      }
    })
  }

  // enable/disable submit button depending on whether form is valid or not
  toggleSubmit(){
    this.submitTarget.disabled = !this.formIsValid
  }

  get formIsValid(){
    let docsExisting = (this.existingDocumentsValue == 'yes')
    let docsRequired = (this.uploadValue == 'required')
    let boxChecked = !this.hasCheckboxTarget || this.checkboxTarget.checked
    let durationProvided = this.hasDurationTarget ? this.durationTarget.value : true
    let metricsProvided = this.hasMetricTarget ? (this.metricTotal > 0.0) : true
    let dateSpecified = !!this.dateTarget.value
    let docRequirementMet = !docsRequired || (docsRequired && docsExisting)
    return docRequirementMet && boxChecked && dateSpecified && durationProvided && metricsProvided
  }

  get metricTotal(){
    let total = this.metricTargets
    .filter((element) => !isNaN(element.value))
    .map(element => parseFloat(element.value))
    .reduce((previousValue, currentValue) => previousValue + currentValue,0.0);
    console.log('total is',total)
    return total
  }

  connect() {
    var controller = this
    console.log('user',this.hasUserValue)
    console.log('upload val:',this.uploadValue)
    console.log('existingDocuments val:',this.existingDocumentsValue)
    this.toggleRadio()
    this.toggleSubmit()
    this.toggleSelfCertify()
    var zoneStyle = window.getComputedStyle(this.element)
    if (!this.hasZoneTarget){
      return true;
    }
    var uppy = Uppy({
      autoProceed: true,
      allowMultipleUploads: true,
      debug: true,
      meta: {
        user: this.userSgid,
        organization: this.organizationSgid,
        attachment: this.attachment
      },
      onBeforeFileAdded: (currentFile, files) => {
        console.log(currentFile, files);
        return true;
      }
    })
    .on('error', (error) => {
      if (window.Rollbar){
        window.Rollbar.error("Uppy upload error", error);
      }
    })
    .on('upload-error', (file, error, response) => {
      if (window.Rollbar){
        window.Rollbar.error("Uppy upload-error", error);
      }
    })
    .on('upload', (data) => {
      console.log("upload!", data)
    })
    .on('upload-success', (response) => {
      console.log("upload-success!", response)
    })
    .on('complete', (result) => {
      this.existingDocumentsValue = 'yes'
      this.toggleSubmit()
      console.log("complete!",result)
      result.successful.forEach((file, i) => {
        var filedata = {
          upload_url: file.uploadURL,
          name: file.name,
          type: file.type,
          size: file.size,
          metadata: file.meta,
          visibility: 'public',
        }
        var payload = {upload: filedata}
        if (controller.hasUserValue){
          payload.user = controller.userValue
        }
        fetch('/training_document/upload.json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-Csrf-Token': this.csrfToken,
            'Accept': 'application/json',
          },
          body: JSON.stringify(payload),
          credentials: 'same-origin',
        }).then((response) => {
          var json = response.json()
          console.log('parsed!')
          console.log(json)
          controller.doneTargets.forEach((target,i)=>{
            console.log("done:",target)
            controller.showElement(target)
            target.disabled = false
          })
        });
      })
    })
    .use(Dashboard, {
      inline: true,
      target: this.zoneTarget,
      width: parseInt(zoneStyle.width),
      height: Math.max(parseInt(window.innerHeight / 4),200),
      showLinkToFileUploadResult: false,
      hideProgressAfterFinish: true,
      proudlyDisplayPoweredByUppy: false,
    })
    .use(AwsS3Multipart, {companionUrl: '/'})
    // .use(Tus, {endpoint: this.tusEndpoint})
  }

  get attachment(){
    try {
      return this.element.dataset.attachedTo
    } catch (e) {
      return null
    }
  }
}
