import { ApplicationController } from "./application_controller.js"
export default class extends ApplicationController   {
  static targets = [ "fieldType","selectValueBlock" ]
  connect() {
    console.log("task_sheet_custom_field")
    console.log(this.element)
    this.change()
  }

  change(){
    if (this.fieldTypeTarget.value == "select") {
      this.selectValueBlockTarget.classList.remove("hidden")
    } else {
      this.selectValueBlockTarget.classList.add("hidden")
    }
  }

}
