import { ApplicationController } from "./application_controller.js"
export default class extends ApplicationController   {
  static targets = [ "departmentSelect", "requirementType", "requirement"]
  connect() {
//    console.log(this.departmentSelectTarget)
  }
  departmentScope(){
    if (!this.hasDepartmentSelectTarget) {
      return true;
    }
    if (this.departmentSelectTarget.selectedIndex > 0){
      var selectedOption = this.departmentSelectTarget.getElementsByTagName('option')[this.departmentSelectTarget.selectedIndex]
      var departmentIds = selectedOption.dataset.hierarchy.split(',')
      // hide individual requirement rows
      this.requirementTargets.forEach((row, i) => {
        var reqDepartments = row.dataset.departments.split(',')
        if (departmentIds.filter(value => reqDepartments.includes(value)).length > 0){
          // overlap, so keep it
          this.removeClass(row, 'hidden')
        } else {
          this.addClass(row, 'hidden')
        }
      })
      // cycle through each requirement type header row
      this.requirementTypeTargets.forEach((rtRow, i) => {
        var showing = false
        this.requirementTargets.forEach((reqRow, i) => {
          // determine if there are any visible requirements for this
          // requirement type. If not, hide the requirement type row as well.
          if (rtRow.dataset.requirementType == reqRow.dataset.requirementType){
            if (!reqRow.classList.contains('hidden')){
              showing = true
            }
          }
        })
        if (showing){
          this.removeClass(rtRow, 'hidden')
        } else {
          this.addClass(rtRow, 'hidden')
        }
      })

    } else {
      this.requirementTargets.forEach((row, i) => {
        this.removeClass(row, 'hidden')
      })
      this.requirementTypeTargets.forEach((row, i) => {
        this.removeClass(row, 'hidden')
      })
    }



  }
}
