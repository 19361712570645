import { ApplicationController } from "./application_controller.js"
import dragula from "dragula"
// for drag and drop, mark container with:
//   data-controller="reorder"
//   data-reorder-url="/path/for/reorder"
// and individual sortables with:
//   data-sortable-id="123"'
// Will use element with class of "drag-handle" for the drag handle,
// otherwise configure the class below:
//
// optional:
//   data-drag-handle-class="class-of-drag-handle"
//   data-container-accept-class="class-of-container-that-will-accept-dragged-thing"
//   data-container-accept-id="id-of-container-that-will-accept-dragged-thing"
//
// server side controller should look for params[:reordered_ids]
//

export default class extends ApplicationController   {
  connect() {
    var token = this.csrfToken
    var dragHandleClass = 'drag-handle'
    var config = {
      moves: function (el, source, handle, sibling) {
          return handle.classList.contains(dragHandleClass);
      },
      isContainer: function (el) {
        return false; // only elements in drake.containers will be taken into account
      }
    }
    if (this.element.dataset.dragHandleClass) {
      dragHandleClass = this.element.dataset.dragHandleClass
      config.moves = function (el, source, handle, sibling) {
          return handle.classList.contains(dragHandleClass);
      }
    }
    if (this.element.dataset.containerAcceptClass) {
      var containerAcceptClass = this.element.dataset.containerAcceptClass
      config.accepts = function(el,target,source,sibling){
        return el.classList.contains(containerAcceptClass)
      }
    }
    if (this.element.dataset.containerAcceptId) {
      var containerAcceptId = this.element.dataset.containerAcceptId
      config.accepts = function(el,target,source,sibling){
        return target.id == containerAcceptId
      }
    }
    dragula([this.element],config)
      .on("drop",function(el, target, source, sibling){
        var url = target.dataset.reorderUrl
        var elements = target.querySelectorAll("[data-sortable-id]")
        var sorted = []
        for (var i = 0; i < elements.length; ++i) {
          sorted.push(elements[i].dataset.sortableId)
        }
        var data = {
          reordered_ids: sorted
        }
        fetch(url, {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(data), // data can be `string` or {object}!
          credentials: 'include',
          headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': token
          },
          credentials: 'same-origin',
        });
      });
  }
}
