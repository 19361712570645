import { ApplicationController } from "./application_controller.js"
import videojs from 'video.js'
import qualityselector from 'videojs-qualityselector/dist/videojs-qualityselector'
// videojs.registerPlugin('playlist', qualityselector);
export default class extends ApplicationController {
  static targets = [
    'video'
  ];
  connect(){
    console.log(this.videoTarget, this.videoTarget.player, typeof this.videoTarget.player)
    if (this.videoTarget.player){
      return;
    }
    console.log('initialized with', this.videoTarget, this.videoTarget.player)
    var sources=[];
    var formats=[];
    var sourceCollection = this.videoTarget.getElementsByTagName('source');
    for(var i = 0;i < sourceCollection.length; i++)
    {
      sources.push({format: sourceCollection[i].dataset.format, src: sourceCollection[i].src})
      formats.push({code: sourceCollection[i].dataset.format, name: sourceCollection[i].dataset.format})
    }
    // this.videoTarget.getElementsByTagName('source').forEach((sourceTarget, i) => {
    //   sources.push({format: sourceTarget.dataSet.format, src: sourceTarget.src})
    //   formats.push({code: sourceTarget.dataSet.format, name: sourceTarget.src})
    // })

    console.log({sources: sources, formats: formats})
    // find the video tag and read the sources
    videojs(this.videoTarget,{
      controls: true,
      preload: 'auto',
      fluid: true,
      responsive: true,
      aspectRatio: '16:9',
    })
    // .qualityselector({sources: sources, formats: formats})
    // provide a 16x9 div
    var containerStyle = getComputedStyle(this.element);
    var computedWidth = parseInt(containerStyle.width);
    var newContainerHeight = parseInt(window.innerHeight) * 0.95;
    var newContainerWidth = newContainerHeight * 16 / 9;
    if (newContainerWidth > computedWidth){
      console.log('no can do,',newContainerWidth, newContainerHeight);
      newContainerWidth = computedWidth * 0.95;
      newContainerHeight = newContainerWidth * 9 / 16;
      // new width will be wider than the current width
    }
    console.log('resizing... ',newContainerWidth, newContainerHeight);
    this.element.style.width = parseInt(newContainerWidth) + 'px';
    this.element.style.height = parseInt(newContainerHeight) + 'px';
  }

}
