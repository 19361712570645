import { ApplicationController } from "./application_controller.js"
import Modernizr from 'modernizr';
import PDFObject from 'pdfobject'
// used when accepts_nested_attributes_for is used in a form.
// set data-controller="nested-attributes-row" on the form tag,
// add a button with data-action="nested-attributes-row#add" to add a row
// set data-target="nested-attributes-row.row" on each row to be added/removed
// see views/task_sheet/template_assignments/new.html.erb for an example
export default class extends ApplicationController   {
  static targets = [ "fullScreenButton","container" ]
  connect() {
    var pdfUrl = this.containerTarget.dataset.pdfUrl
    var opts = {width: "100%"}
    var height = parseInt(window.innerHeight * 0.7) + "px"
    opts.height = height;
    if (PDFObject.supportsPDFs){
      PDFObject.embed(pdfUrl, this.containerTarget, opts)
      this.addClass(this.element,'inline-pdfs')
    } else {
      this.addClass(this.element,'no-inline-pdfs')
      if (this.hasFullScreenButtonTarget) {
        this.fullScreenButtonTarget.style.display = 'none'        
      }
    }
  }

  fullScreen(event){
    event.preventDefault();
    if (!Modernizr.fullscreen){ return true; }
    this.containerTargets.forEach((containerTarget, i) => {
      var embed = containerTarget.getElementsByTagName('embed')[0]
      if (!embed){
        embed = containerTarget.getElementsByTagName('img')[0]
      }
      if (!embed) {return true;}
      if (window.getComputedStyle(containerTarget).display === "block"){
        if (embed.requestFullscreen) {
          embed.requestFullscreen();
        } else if (embed.mozRequestFullScreen) { /* Firefox */
          embed.mozRequestFullScreen();
        } else if (embed.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          embed.webkitRequestFullscreen();
        } else if (embed.msRequestFullscreen) { /* IE/Edge */
          embed.msRequestFullscreen();
        }
      }
    })
  }
}
