import { ApplicationController } from "./application_controller.js"
export default class extends ApplicationController   {
  static targets = [ "container","checkbox" ]
  connect() {
    console.log("Hello, Stimulus!", this.element)
    this.toggle()
  }
  toggle() {
    console.log("toggle!")
    if (this.checkboxTarget && this.checkboxTarget.checked) {
      this.containerTargets.forEach((el, i) => {
        el.classList.add("hidden")
      })
    } else {
      this.containerTargets.forEach((el, i) => {
        el.classList.remove("hidden")
      })
    }

  }
}
