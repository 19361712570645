import { ApplicationController } from "./application_controller.js"
export default class extends ApplicationController   {
  static targets = [ "input","questionType","multipleChoice","trueFalse","destroyBox" ]
  connect() {
    console.log("quiz-question-builder")
    this.displayChoices()
  }

  displayChoices(){
    if (this.questionTypeTarget.value == "true_false") {
      this.trueFalseTargets.forEach((trueFalseTarget, i) => {
        this.showElement(trueFalseTarget)
      })
      this.multipleChoiceTargets.forEach((multipleChoiceTarget, i) => {
        this.hideElement(multipleChoiceTarget)
      })
    } else {
      this.trueFalseTargets.forEach((trueFalseTarget, i) => {
        this.hideElement(trueFalseTarget)
      })
      this.multipleChoiceTargets.forEach((multipleChoiceTarget, i) => {
        this.showElement(multipleChoiceTarget)
      })
    }
  }

  cancelNew(event){
    event.preventDefault()
    this.element.parentNode.removeChild(this.element)
  }

  removeQuestion(){
    this.destroyBoxTargets.forEach((destroyBoxTarget,j) => {
      this.inputTargets.forEach((inputTarget, i) => {
        if (inputTarget.dataset.question === destroyBoxTarget.dataset.question) {
          inputTarget.disabled = destroyBoxTarget.checked
        }
      })
    })
  }
}
