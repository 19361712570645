import { ApplicationController } from "./application_controller.js"
export default class extends ApplicationController   {
  static targets = [
    "pattern", "block", "anyBlock"
  ]
  connect() {
    this.blockTargets.forEach((elem) => {
      this.hideAndDisable(elem)
    })
    this.display()
  }

  display(){
    this.anyBlockTargets.forEach((elem) => {
      if ((this.patternTarget.value == "") || (this.patternTarget.value == "none")) {
        this.hideAndDisable(elem)
      } else {
        this.showAndEnable(elem)
      }
    })
    this.blockTargets.forEach((elem) => {
      if (elem.dataset.block == this.patternTarget.value) {
        this.showAndEnable(elem)
      } else {
        this.hideAndDisable(elem)
      }
    })
  }

  hideAndDisable(elem){
    this.hideElement(elem)
    this.addClass(elem,'disabled')
    // elem.getElementsByTagName('input').forEach((input)=> {input.disabled = true})
    // elem.getElementsByTagName('select').forEach((input)=> {input.disabled = true})
  }

  showAndEnable(elem){
    this.showElement(elem)
    this.removeClass(elem,'disabled')
    // elem.getElementsByTagName('input').forEach((input)=> {input.disabled = false})
    // elem.getElementsByTagName('select').forEach((input)=> {input.disabled = false})
  }
}
