import { ApplicationController } from "./application_controller.js"
// when target select-all is checked, all of the checkbox targets will
// also be checked.
// when action toggleOne or toggleAll are triggered, the button targets
// will be enabled if any checkbox target is checked

export default class extends ApplicationController   {
  static targets = [ "selectAll","checkbox","button" ]
  connect() {
    console.log("sac")
  }

  toggleOne(e){
    this.toggleButtons()
  }

  toggleAll(e){
    this.checkboxTargets.forEach((checkbox, i) => {
      checkbox.checked = this.selectAllTarget.checked
    })
    this.toggleButtons()
  }

  toggleButtons(){
    var disableButtons = true
    this.checkboxTargets.forEach((checkbox, i) => {
      if (checkbox.checked){
        disableButtons = false
      }
    })
    this.buttonTargets.forEach((button, i) => {
      button.disabled = disableButtons
    })
  }
}
