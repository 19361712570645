import { ApplicationController } from "./application_controller.js"
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
export default class extends ApplicationController   {
  static targets = [ 'zone', 'attachment','uploads','done' ]
  static values = { user: String }
  connect() {
    var controller = this
    console.log('user',this.hasUserValue)
    var zoneStyle = window.getComputedStyle(this.element)
    if (!this.hasZoneTarget){
      return true;
    }
    var uppy = Uppy({
      autoProceed: true,
      allowMultipleUploads: true,
      debug: true,
      meta: {
        user: this.userSgid,
        organization: this.organizationSgid,
        attachment: this.attachment
      },
      onBeforeFileAdded: (currentFile, files) => {
        console.log(currentFile, files);
        return true;
      }
    })
    .on('error', (error) => {
      if (window.Rollbar){
        window.Rollbar.error("Uppy upload error", error);
      }
    })
    .on('upload-error', (file, error, response) => {
      if (window.Rollbar){
        window.Rollbar.error("Uppy upload-error", error);
      }
    })
    .on('upload', (data) => {
      console.log("upload!", data)
    })
    .on('upload-success', (response) => {
      console.log("upload-success!", response)
    })
    .on('complete', (result) => {
      console.log("complete!",result)
      result.successful.forEach((file, i) => {
        var filedata = {
          upload_url: file.uploadURL,
          name: file.name,
          type: file.type,
          size: file.size,
          metadata: file.meta,
          visibility: 'public',
        }
        var payload = {upload: filedata}
        if (controller.hasUserValue){
          payload.user = controller.userValue
        }
        fetch('/training_document/upload.json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-Csrf-Token': this.csrfToken,
            'Accept': 'application/json',
          },
          body: JSON.stringify(payload),
          credentials: 'same-origin',
        }).then((response) => {
          var json = response.json()
          console.log('parsed!')
          console.log(json)
          controller.doneTargets.forEach((target,i)=>{
            console.log("done:",target)
            controller.showElement(target)
            target.disabled = false
          })
        });
      })
    })
    .use(Dashboard, {
      inline: true,
      target: this.zoneTarget,
      width: parseInt(zoneStyle.width),
      height: Math.max(parseInt(window.innerHeight / 4),200),
      showLinkToFileUploadResult: false,
      hideProgressAfterFinish: true,
      proudlyDisplayPoweredByUppy: false,
    })
    .use(AwsS3Multipart, {companionUrl: '/'})
    // .use(Tus, {endpoint: this.tusEndpoint})
  }

  get attachment(){
    try {
      return this.element.dataset.attachedTo
    } catch (e) {
      return null
    }
  }


}



/*

  stimulus: define controller as the uppy dropzone and the list of uploads
    uppy.zone: dropzone
    uppy.uploads: list of uploads

  on successful upload:
    post to blob/uploads
    get success reponse back
    grab the parent URL
    get the HTML
    replace uppy.uploads with

*/
