import { ApplicationController } from "./application_controller.js"
import Modernizr from 'modernizr';

// const { connect: twilioConnect } = require('twilio-video');
export default class extends ApplicationController   {
  static targets = [
    'participants','myMedia','videoContainer','consoleContainer',
    'myMediaContainer','recordingIndicator','logContainer','startButton',
    'startAudioButton','hangUpButton'
  ];

  fullScreen(element){
    if (!Modernizr.fullscreen){ return true; }
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) { /* Firefox */
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) { /* IE/Edge */
      element.msRequestFullscreen();
    }
  }

  fullScreenVideo(e){
    console.log('full screen!', e);
    var button = e.target;
    var participant = button.closest('.participant');
    var video = participant.getElementsByTagName('video')[0];
    if (video){
      this.fullScreen(video);
    }
  }

  muteAll(){
    if (this.localDataTrack){
      this.sendMessage({muteAllAudio: 1})
    }
  }

  toggleAudio(){
    var controller = this;
    console.log('click toggleaudio')
    this.localDataTrack.send({boo: 'ya'});
    if (this.audioTrack){
      console.log('enable:',!this.audioTrack.isEnabled)
      this.audioTrack.enable(!this.audioTrack.isEnabled);
      if (this.audioTrack.isEnabled){
        this.myMediaContainerTarget.classList.add('audio-on');
        this.myMediaContainerTarget.classList.remove('audio-off');
      } else {
        this.myMediaContainerTarget.classList.add('audio-off');
        this.myMediaContainerTarget.classList.remove('audio-on');
      }
    } else {
      console.log('create:')
      this.video.createLocalAudioTrack({
        name: ('audio-track-' + controller.userId),
        workaroundWebKitBug180748: true
      }).then(function(localTrack) {
        controller.audioTrack = localTrack;
        controller.room.localParticipant.publishTrack(localTrack);
        controller.myMediaContainerTarget.classList.add('audio-on');
        controller.myMediaContainerTarget.classList.remove('audio-off');
      });
    }
  }

  connect(){
    this.startButtonTarget.classList.remove('hidden');
  }

  hangUp(){
    this.room.disconnect();
  }


  toggleVideo(){
    console.log('click togglevideo')
    this.localDataTrack.send({boo: 'ya'});
    var controller = this;
    var room = this.room;
    var track = this.videoTrack;
    if (this.videoTrack){
      console.log('enable:',!this.videoTrack.isEnabled)
      this.videoTrack.enable(!this.videoTrack.isEnabled);
      if (this.videoTrack.isEnabled){
        this.myMediaContainerTarget.classList.add('video-on');
        this.myMediaContainerTarget.classList.remove('video-off');
      } else {
        this.myMediaContainerTarget.classList.add('video-off');
        this.myMediaContainerTarget.classList.remove('video-on');
      }
    } else {
      console.log('create:')
      this.video.createLocalVideoTrack({
        width: Math.min(320, window.innerWidth),
        name: ('video-track-' + this.userId)
      }).then(function(localTrack) {
        controller.videoTrack = localTrack;
        const div = document.createElement('div');
        div.id = room.localParticipant.sid;
        const media = localTrack.attach();
        media.style.transform = 'scale(-1, 1)';
        // media.classList.add('embed-responsive-item');
        div.appendChild(media);
        controller.myMediaTarget.appendChild(div);
        controller.myMediaContainerTarget.classList.add('video-on');
        controller.myMediaContainerTarget.classList.remove('video-off');
        controller.room.localParticipant.publishTrack(localTrack);
        localTrack.enable(true);
      });
    }
  }

  toggleTest(e){
    console.log('toggleTest!', e);
  }

  playAll(){
    Array.from(document.getElementsByTagName('audio')).forEach(audio => {audio.play()});
    Array.from(document.getElementsByTagName('video')).forEach(audio => {audio.play()});
    this.startAudioButtonTarget.classList.add('hidden');
  }
  start() {
    var controller = this;
    this.myMediaContainerTarget.classList.remove('hidden');
    this.logContainerTarget.classList.remove('hidden');
    this.startButtonTarget.classList.add('hidden');

    this.video = require('twilio-video');
    this.log('hello video chat');
    this.userId = document.querySelector('meta[property=user_id]').content;
    this.localDataTrack = new this.video.LocalDataTrack();
    this.dataTrackPublished = {};
    this.dataTrackPublished.promise = new Promise((resolve, reject) => {
      this.dataTrackPublished.resolve = resolve;
      this.dataTrackPublished.reject = reject;
    });
    this.myMediaContainerTarget.classList.add('audio-off');
    this.myMediaContainerTarget.classList.add('video-off');
    this.video.connect(this.element.dataset.token, {
      name: this.element.dataset.room,
      dominantSpeaker: true,
      audio: false,
      video: false,
      tracks: [this.localDataTrack],
    }).then(room => {
      controller.room = room;
      var localParticipant = room.localParticipant;
      controller.log(`Successfully joined a Room: ${room}`);
      console.log(room);
      if (room.isRecording){
        controller.recordingIndicatorTarget.classList.remove('hidden');
      }
      controller.hangUpButtonTarget.classList.remove('hidden');
      controller.log(`Connected to the Room as LocalParticipant "${localParticipant.identity}"`);
      console.log(room.participants);
      var li = document.createElement('li')
      li.classList.add(localParticipant.sid, 'participant-li', 'local-participant', 'connected');
      var txt = document.createTextNode(localParticipant.identity);         // Create a text node
      li.appendChild(txt);
      controller.participantsTarget.appendChild(li);
      window.addEventListener('unload', function(event) {
       controller.room.disconnect();
      });
      // controller.participantConnected(localParticipant);
      room.participants.forEach(participant => controller.participantConnected(participant, controller));
      room.on('participantConnected', participant => controller.participantConnected(participant, controller));
      room.on('participantDisconnected', participant => controller.participantDisconnected(participant, controller));
      room.once('disconnected', error => room.participants.forEach(participant => controller.participantDisconnected(participant, controller)));
      room.on('disconnected', function(room, error) {
        if (error) {
          alert('You were unexpectedly disconnected from controller web conference: ' + error);
        } else {
          alert('You have left the meeting.');
        }
        if (controller.audioTrack){
          controller.audioTrack.stop();
          controller.audioTrack = null;
        }
        if (controller.videoTrack){
          controller.videoTrack.detach();
          controller.videoTrack.stop();
          controller.videoTrack = null;
        }
        if (document.getElementById(room.localParticipant.sid)){
          document.getElementById(room.localParticipant.sid).remove();
        }
        Array.from(
          controller.participantsTarget.getElementsByClassName('participant-li')
        ).forEach(li => { li.remove(); });
        controller.myMediaContainerTarget.classList
          .remove('audio-on', 'audio-off', 'video-on', 'video-off');
        controller.hangUpButtonTarget.classList.add('hidden');
        controller.recordingIndicatorTarget.classList.add('hidden');
        controller.myMediaContainerTarget.classList.add('hidden');
        controller.logContainerTarget.classList.add('hidden');
        controller.startButtonTarget.classList.remove('hidden');
        controller.startAudioButtonTarget.classList.add('hidden');

      });
      room.on('dominantSpeakerChanged', participant => controller.dominantSpeakerChanged(participant, controller));
      room.localParticipant.on('trackPublished', publication => {
        if (publication.track === controller.localDataTrack) {
          controller.dataTrackPublished.resolve();
        }
      });
      room.localParticipant.on('trackPublicationFailed', (error, track) => {
        if (track === controller.localDataTrack) {
          controller.dataTrackPublished.reject(error);
        }
      });
    }, error => {
      console.error(`Unable to connect to Room: ${error.message}`);
    });
  }

  sendMessage(message){
    this.dataTrackPublished.promise.then(() => this.localDataTrack.send(JSON.stringify(message)));
  }

  log(data){
    console.log(data);
    var para = document.createElement("p");
    var textnode = document.createTextNode(data);         // Create a text node
    para.appendChild(textnode);
    this.consoleContainerTarget.appendChild(para);
  }

  dominantSpeakerChanged(participant,controller){
    this.log(`The new dominant speaker in the Room is ${participant}`);
    var speakers = controller.videoContainerTarget.getElementsByClassName('speaking');
    while (speakers.length > 0) {
      speakers[0].classList.remove('speaking');
    }
    if (participant){
      document.getElementById(participant.sid).classList.add('speaking');
    }
  }

  isPromise(value) {
    return value !== undefined && typeof value.then === 'function';
  }

  participantConnected(participant, controller) {
    console.log('Participant "%s" connected', participant.identity);
    const div = document.getElementById('participant-template').cloneNode(true);
    div.id = participant.sid;

    div.classList.add('participant');
    const nameDiv = div.getElementsByClassName('participant-name')[0];
    nameDiv.innerText = participant.identity;
    controller.videoContainerTarget.appendChild(div);
    const mediaDiv = div.getElementsByClassName('media-container')[0];
    participant.tracks.forEach(publication => {
      console.log(publication)
      if (publication.isSubscribed) {
        const track = publication.track;
        console.log('P-Conn: pushing play on ', track);
        var media = controller.playTrack(track);
        mediaDiv.appendChild(media);
      }
    });
    var li = document.createElement('li')
    li.classList.add(participant.sid, 'participant-li', 'remote-participant', 'connected');
    var txt = document.createTextNode(participant.identity);         // Create a text node
    li.appendChild(txt);
    this.participantsTarget.appendChild(li);

    participant.on('trackSubscribed', track => this.trackSubscribed(mediaDiv, track));
    participant.on('trackUnsubscribed', track => this.trackUnsubscribed(track));
    participant.on('trackEnabled', track => { console.log('trackEnabled', track);});
    participant.on('trackDisabled', track => { console.log('trackDisabled', track);});
    participant.on('trackEnabled', track => { console.log('trackEnabled', track);});
    participant.on('trackPublished', track => { console.log('trackPublished', track);});
    participant.on('trackStarted', track => { console.log('trackStarted', track);});
    participant.on('trackSubscribed', track => { console.log('trackSubscribed', track);});
    participant.on('trackEnabled', track => {this.trackEnabled(track)});
    participant.on('trackDisabled', track => {this.trackDisabled(track)});
    participant.on('trackDisabled', track => this.removeMediaClassFromParticipantDiv(participant, track));
    participant.on('trackEnabled', track => this.addMediaClassToParticipantDiv(participant, track));

    participant.on('trackUnsubscribed', track => this.removeMediaClassFromParticipantDiv(participant, track));
    participant.on('trackSubscribed', track => this.addMediaClassToParticipantDiv(participant, track));
    participant.on('trackSubscribed', track => {
      console.log(`Participant "${participant.identity}" added ${track.kind} Track ${track.sid}`);
      if (track.kind === 'data') {
        track.on('message', data => {
          try {
            var payload = JSON.parse(data);
            console.log('json message for you sir ', payload);
            if (payload.muteAllAudio && controller.audioTrack){
              controller.audioTrack.enable(false);
            }
          } catch (e) {
            console.log('plain text message for you sir ', data);
          }
        });
      }
    });
    this.sendMessage({boo: 1});
  }

  addMediaClassToParticipantDiv(participant, track){
    var div = document.getElementById(participant.sid);
    if (div){
      div.classList.add('with-' + track.kind);
    }
  }
  removeMediaClassFromParticipantDiv(participant, track){
    var div = document.getElementById(participant.sid);
    if (div){
      div.classList.remove('with-' + track.kind);
    }
  }

 participantDisconnected(participant,controller) {
  controller.log(`${participant.identity} has left the room.`);
  document.getElementById(participant.sid).remove();
  var items = document.getElementsByClassName(participant.sid);
  while (items.length > 0) {
    items[0].remove();
  }
 }

 playTrack(track){
   var media = track.attach();
   var controller = this;
   if (['video','audio'].includes(track.kind)) {
     console.log('push play');
     if (track.kind == 'video'){
       media.muted = true;
     }
     var promise = media.play();
     if (this.isPromise(promise)){
       promise.then(
         () => {console.log('success!');},
         (e) => {
          if (media.paused){
            console.log('un-hiding audio button');
            controller.startAudioButtonTarget.classList.remove('hidden');
          } else {
            console.log('nothing paused here, good to go');
          }
       });
     } else {
       console.log('.play() is not a promise');
     }
   }
   return media;
 }

 trackSubscribed(div, track) {
   this.log('Track subscribed');
   console.log('track subscribed:', track);
   if (typeof track.attach == 'function'){
     console.log('track-sub: pushing play on ',track);
     var media = this.playTrack(track);
     media.id = track.sid;
     if (track.isSwitchedOff){
       media.classList.add('muted');
     } else {
       media.classList.add('unmuted');
     }
     div.appendChild(media);
   }
  }
  trackEnabled(track){
    var media = document.getElementById(track.trackSid);
    if (media){
      media.classList.add('unmuted');
      media.classList.remove('muted');
    }
  }
  trackDisabled(track){
    var media = document.getElementById(track.trackSid);
    if (media){
      media.classList.add('muted');
      media.classList.remove('unmuted');
    }
  }

  trackUnsubscribed(track) {
    if (typeof track.detach == 'function'){
      track.detach().forEach(element => element.remove());
    }
    console.log('trackUnsub: ', track);
  }
}
