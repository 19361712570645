import { ApplicationController } from "./application_controller.js"
// message/show, handle reply/reply all actions
// message/new, handle display on main page hide/show dates options
export default class extends ApplicationController   {
  static targets = [
    "reply","recipients","replyButton","replyAllButton",
    "displayOnMainPage","displayDate"
  ]
  connect() {
    console.log("message-show")
    this.toggleMainPage()
  }

  reply(){
    console.log("reply!")
    this.replyTarget.style.display="block"
    var recips = this.replyButtonTarget.dataset.recipients.split(",")
    console.log(recips)
    this.recipientsTarget.querySelectorAll("option").forEach((option, i) => {
      option.selected = (recips.indexOf(option.value) >= 0);
      // console.log(option.selected, 'for',option.value)
    })
    var event = new Event('change');
    this.recipientsTarget.dispatchEvent(event);
  }

  replyAll(){
    console.log("reply all!")
    this.replyTarget.style.display="block"
    var recips = this.replyAllButtonTarget.dataset.recipients.split(",")
    console.log(recips)
    this.recipientsTarget.querySelectorAll("option").forEach((option, i) => {
      option.selected = (recips.indexOf(option.value) >= 0);
      // console.log(option.selected, 'for',option.value)
    })
    var event = new Event('change');
    this.recipientsTarget.dispatchEvent(event);
  }

  discard(e){
    e.preventDefault()
    this.replyTarget.style.display="none"
  }

  selectAllRecipients(e){
    e.preventDefault()
    console.log('select all')
    this.recipientsTarget.querySelectorAll("optgroup[label='Staff'] option").forEach((option, i) => {

      option.selected = true;
    })
    var event = new Event('change');
    this.recipientsTarget.dispatchEvent(event);
  }

  removeAllRecipients(e){
    e.preventDefault()
    console.log('select none')
    this.recipientsTarget.querySelectorAll("option").forEach((option, i) => {
      option.selected = false;
    })
    var event = new Event('change');
    this.recipientsTarget.dispatchEvent(event);
  }

  toggleMainPage(){
    this.displayDateTargets.forEach((target, i) => {
      if (this.displayOnMainPageTarget.checked){
        target.style.display = "block"
      } else {
        target.style.display = "none"
      }
    })
  }
}
