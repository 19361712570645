import { ApplicationController } from "./application_controller.js"
import MD5 from 'md5.js'
// assign data-controller='collapsed-box' to the parent element
// assign data-target='collapsed-box.collapsable' to the element to be collapsed
// assign data-action='click->collapsed-box#toggle' to the element that will
//   expand/collapse the element.
// on initialize, a + or - icon will be added within the toggle element
// click the toggle element, and the collapsable target should expand/collapse
// can set initial state of collapsable with data-initial-state='closed' or data-initial-state='open'
// state is remembered using session storage in the browser, based on the ID of the
//   parent element and the URL
// see views/shared/assigned_training/show.html.erb for an example
export default class extends ApplicationController   {
  static targets = [ "trigger","collapsable","descriptor" ]
  connect() {
    console.log("new controller",this.element, this.element.id)

    this.collapsableTargets.forEach((collapsable, i) => {
      console.log("found target", collapsable)
      collapsable.dataset.initialHeight = collapsable.clientHeight + 'px';
      collapsable.dataset.paddingTop = window.getComputedStyle(collapsable).paddingTop;
      collapsable.dataset.paddingBottom = window.getComputedStyle(collapsable).paddingBottom;
      collapsable.style.maxHeight = collapsable.clientHeight + 'px';
      collapsable.style.height = collapsable.clientHeight + 'px';
      collapsable.style.paddingTop = collapsable.dataset.paddingTop;
      collapsable.style.paddingBottom = collapsable.dataset.paddingBottom;
    })

    console.log("initial state", this.initialState())
    if (this.initialState() == "open") {
      this.setState("open")
      this.descriptorTargets.forEach((descriptor, i) => {
        descriptor.innerHTML = "Collapse"
      })
      this.triggerTargets.forEach((trigger,i) => {
        this.triggerOpen(trigger)
      })
    } else {
      this.setState("closed")
      this.descriptorTargets.forEach((descriptor, i) => {
        descriptor.innerHTML = "Expand"
      })
      this.collapsableTargets.forEach((collapsable, i) => {
        collapsable.style.maxHeight = '0px';
        collapsable.style.paddingTop = '0px';
        collapsable.style.paddingBottom = '0px';
      })
      this.triggerTargets.forEach((trigger,i) => {
        this.triggerClose(trigger)
      })
    }
  }

  expandAll(event){
    var triggers = this.element.getElementsByClassName("collapsed-box-trigger-closed");
    console.log(triggers, triggers.length)
    var collection = [];
    for (var i = 0; i < triggers.length; i++) {
      collection.push(triggers[i]);
    }
    collection.forEach((element,i) => {element.click()} )
    event.preventDefault()
    event.stopImmediatePropagation()
  }

  collapseAll(event){
    var triggers = this.element.getElementsByClassName("collapsed-box-trigger-open");
    console.log(triggers, triggers.length)
    var collection = [];
    for (var i = 0; i < triggers.length; i++) {
      collection.push(triggers[i]);
    }
    collection.forEach((element,i) => {element.click()} )
    event.preventDefault()
    event.stopImmediatePropagation()
  }

  toggle(event){
    event.preventDefault()
    event.stopImmediatePropagation()
    if (this.element.dataset.collapse == "open") {
      console.log("closing")
      this.setState("closed")
      this.descriptorTargets.forEach((descriptor, i) => {
        descriptor.innerHTML = "Expand"
      })
      this.triggerTargets.forEach((trigger,i) => {
        this.triggerClose(trigger)
      })

      this.collapsableTargets.forEach((collapsable, i) => {
        collapsable.style.height = collapsable.dataset.initialHeight
        collapsable.style.maxHeight = '0px'
        collapsable.style.paddingTop = '0px';
        collapsable.style.paddingBottom = '0px';

      })
    } else {
      console.log("opening")
      this.setState("open")
      this.descriptorTargets.forEach((descriptor, i) => {
        descriptor.innerHTML = "Collapse"
      })
      this.triggerTargets.forEach((trigger,i) => {
        this.triggerOpen(trigger)
      })
      this.collapsableTargets.forEach((collapsable, i) => {
        collapsable.style.maxHeight = collapsable.dataset.initialHeight
        collapsable.style.paddingTop = collapsable.dataset.paddingTop;
        collapsable.style.paddingBottom = collapsable.dataset.paddingBottom;
      })
    }
  }

  triggerOpen(trigger){
    trigger.classList.add("glyphicon")
    trigger.classList.remove("collapsed-box-trigger-closed")
    trigger.classList.remove("glyphicon-plus")
    trigger.classList.add("glyphicon-minus")
    trigger.classList.add("collapsed-box-trigger-open")
  }

  triggerClose(trigger){
    trigger.classList.add("glyphicon")
    trigger.classList.add("collapsed-box-trigger-closed")
    trigger.classList.add("glyphicon-plus")
    trigger.classList.remove("glyphicon-minus")
    trigger.classList.remove("collapsed-box-trigger-open")
  }

  setState(state){
    var domId = this.element.id
    var href = window.location.href
    var key = new MD5().update(domId + '|'+ href).digest("hex")
    var existing = sessionStorage.setItem(key,state)
    this.element.dataset.collapse = state
    console.log("setting state using key",key, ' and state ', state)
  }

  getState(){
    var domId = this.element.id
    var href = window.location.href
    var key = new MD5().update(domId + '|'+ href).digest("hex")
    return sessionStorage.getItem(key)
  }


  initialState(){
    var defaultState;

    if (this.getState()){
      return this.getState();
    }
    this.collapsableTargets.forEach((collapsable, i) => {
      if (collapsable.dataset.initialState){
        defaultState =  collapsable.dataset.initialState;
      }
    });
    if (defaultState){
      this.setState(defaultState);
    }
    if ( this.getState() == "closed" ) {
      return "closed"
    } else {
      this.setState("open")
      return "open"
    }
  }
}
